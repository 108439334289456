import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Layout from "src/components/layout"
import Breadcrumbs from "src/components/Breadcrumbs"
import BasicContent from "src/components/BasicContent"
import Img from "gatsby-image"
import Accordion from "src/components/Accordion"
import SubpageBanner from "src/components/SubpageBanner"


const breadcrumbs = [
  {url: "a-better-way", handle: "A Better Way"},
  {url: "a-better-way/importance", handle: "The Importance"}
]

const ClinicalResults = () => (
  <StaticQuery
    query={graphql`
      query {
        background: file(relativePath: { eq: "discarded-cells-banner.jpg" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        },
        ctaOne: file(relativePath: { eq: "cta-1.jpg" }) {
          childImageSharp {
            fixed(width: 400, height: 312) {
              ...GatsbyImageSharpFixed
            }
          }
        },
        ctaTwo: file(relativePath: { eq: "cta-2.jpg" }) {
          childImageSharp {
            fixed(width: 400, height: 312) {
              ...GatsbyImageSharpFixed
            }
          }
        },
        ctaThree: file(relativePath: { eq: "cta-3.jpg" }) {
          childImageSharp {
            fixed(width: 400, height: 312) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `}
    render={data => {
      const background = data.background.childImageSharp.fluid
      const ctaOne = data.ctaOne.childImageSharp.fixed
      const ctaTwo = data.ctaTwo.childImageSharp.fixed
      const ctaThree = data.ctaThree.childImageSharp.fixed

      return (
        <Layout>
          <SubpageBanner image={background} heading="The Importance of a More Complete Sample" />
          <div className="wrapper wrapper--narrow p-bottom p-sides">
            <Breadcrumbs items={breadcrumbs} />
            <BasicContent>
              <h2>
                More Cells. More Results.
              </h2>
              <p>
              While Hologic's ThinPrep continues to be the industry's leading Liquid-Based Pap Test System, there is potential for the system to perform even better. With aid from the exCellerator, clinicians can now capture a more complete sample for processing and testing.
              </p>
              <h3 style={{fontSize: "1.25rem"}}>The lab can only test what it receives.</h3>
            </BasicContent>
            <div className="photos">
              <Img fixed={ctaOne} />
              <Img className="m-sides--tiny" fixed={ctaTwo} />
              <Img fixed={ctaThree} />
            </div>
            <Accordion title="What if a significant portion of the sample collected by the clinician is discarded rather than transferred into the vial for testing?">
              <p>
                <strong style={{color: "#9C69AC"}}>Transferring</strong> the collected sample from the cytobrush can be challenging and highly operator dependent. Given the nature of the sample, the cytobrush design, and its interaction with the fluid, simply ‘swishing’ without aid of the exCellerator does not reliably and consistently release the sample. This can result in a significant portion of the sample being discarded.
              </p>
            </Accordion>
            <Accordion title="What if cells carrying critical information for proper diagnosis, follow-up, and treatment were lost with the discarded sample rather than tested by the laboratory?">
                <p>
                The sample collected with the cytobrush is important as it provides unique and critical sampling of the endocervix and the transformation zone.
                </p>
                <p>
                <strong style={{color: "#9C69AC"}}>The lab cannot test a sample it does not receive</strong>, so the missing a portion of this sample can affect overall specimen adequacy and the diagnostic accuracy of cytologic and molecular testing performed.
                </p>
                <p>
                The importance of a more complete sample cannot be overstated. If cells are discarded, critical information can be lost with potential impact such as:
                </p>
                <ul>
                  <li>Unnecessary unsatisfactory results</li>
                  <li>Missed or under-diagnosed cervical abnormality</li>
                  <li>Reduced sensitivity in ancillary out-of-the-vial testing such as HPV &amp; CT/NG</li>
                </ul>
                <p>
                  A missed or delayed diagnosis might allow for unnecessary disease persistence and/or progression between screening events for women.
                </p>
              </Accordion>
              <Accordion title="What if there was a way for all clinicians and their assistants to achieve a more complete transfer of all samples collected?">
                <p><strong style={{color: "#9C69AC"}}>The FDA-cleared exCellerator</strong> can address this issue in one simple step.</p>
                <p>The exCellerator is a complement to the ThinPrep (TP) Pap Test system, and studies show that it enables users to successfully achieve a more complete transfer of the collected sample into the ThinPrep vial.</p>
                <p>Having a more complete sample can significantly improve testing adequacy and can also provide more sufficient residual sample for ancillary tests that enable more comprehensive and accurate screening for your patient.</p>
                <p>Some <strong style={{color: "#9C69AC"}}>potential benefits of a more complete sample</strong> might include:</p>
                <ul>
                  <li>
                    <strong>Fewer Unsatisfactory or QNS Results:</strong>
                    <ul>
                      <li>Fewer repeat visits and tests</li>
                      <li>Less wasted time and expense for patients</li>
                      <li>More productive use of time and care for clinicians</li>
                      <li>More efficient use of medical insurance dollars</li>
                    </ul>
                  </li>
                  <br />
                  <li>
                    <strong>Earlier detection</strong>
                    <ul>
                      <li>More appropriate and timely treatment for patient</li>
                      <li>Enables clinicians to better care for patients</li>
                      <li>Reduces unnecessary downstream health issues for patients</li>
                      <li>Reduces unnecessary downstream costs for patients, clinicians, labs, and payors</li>
                    </ul>
                  </li>
                </ul>
              </Accordion>
          </div>
        </Layout>
      )
    }}
  />
)

export default ClinicalResults
